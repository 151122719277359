<div class="details">
  <app-loader #detailsLoader cssClasses="loader--white"></app-loader>
  <div *ngIf="data?.img; else noImage" class="details__thumbnail-wrapper">
    <img 
      class="details__thumbnail" 
      [src]="(data?.img | resizeImage: imageSizes.SIZE_640xAUTO | uncache | async ) ?? ''" 
     />
  </div>


  <div class="body-wrapper">
    <div class="details__body">
      <div class="details__body__title fw-bold text-center color-black text-break mb-3 mh-100">
        {{data?.title}}
      </div>
      <div class="w-100 details__body__content" *ngIf="data?.content" [innerHtml]="data?.content">
      </div>
    </div>
  </div>
</div>

<ng-template #noImage>
  <div 
    class="details__no-image"
    [style.background-image]="'url(\'../../../assets/images/catalogue-list/no-article-image.svg\')'"
  ></div>
</ng-template>
