import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {InfoBlockService} from './info-block.service';
import {LoaderComponent} from '../../ui-elements/loader/loader.component';
import {LoaderService} from '../../ui-elements/loader/loader.service';
import {InfoBlockTabsEnum, UIInfoBlockTabsEnum} from './info-block-page-types.enum';
import {BlockPageInterface, UIBlockPageInterface} from './block-page.interface';
import { CatalogueSystemsInterface } from '../products/product.model';
import { FinishesService } from '../../content/finishes/finishes.service';
import { catchError, forkJoin, of } from 'rxjs';
import { FinishFamily, FinishItem } from '../../content/finishes/finishes.model';
import { TranslateService } from '@ngx-translate/core';
import { FileData } from '../../core/models/file-data.model';

@Component({
  selector: 'app-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
  host: { class: 'component-host-scrollable' }
})
export class InfoBlockComponent implements OnInit {
  @Input() system: CatalogueSystemsInterface;
  @ViewChild('loader', { static: true }) loader: LoaderComponent;

  activeTab: BlockPageInterface | UIBlockPageInterface;
  tabs: (BlockPageInterface | UIBlockPageInterface)[] = [];
  pageTypes = InfoBlockTabsEnum;
  uiPageTypes = UIInfoBlockTabsEnum;
  finishes: FinishItem[] = [];
  finishFamilies: FinishFamily[] = [];
  finishDownloadsTab: BlockPageInterface | UIBlockPageInterface | null = null;
  activeTabImages: FileData[] = [];

  constructor(
    public modal: NgbActiveModal,
    private infoBlockService: InfoBlockService,
    private finishesService: FinishesService,
    private loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    const data$ = forkJoin({
      infoBlocks: this.system.infoBlock.hasInfoBlock
        ? this.infoBlockService.getInformation(
            this.system.infoBlock.type,
            this.system.infoBlock.path
          ).noCache().pipe(catchError(() => of(null)))
        : of(null),
      finishes: this.system.infoBlock.hasFinishFamily
        ? this.finishesService.getFinishesByPath(
            this.system.infoBlock.type,
            this.system.infoBlock.path
          ).pipe(catchError(() => of(null)))
        : of(null),
      finishFamilies: this.system.infoBlock.hasFinishFamily
        ? this.finishesService.getFamilyByPath(
            this.system.infoBlock.type,
            this.system.infoBlock.path
          ).pipe(catchError(() => of(null)))
        : of(null)
    });

    this.loaderService.load(
      data$,
      this.loader
    ).subscribe(({infoBlocks, finishes, finishFamilies}) => {
      const blockPages = infoBlocks?.blockPages ?? null;
      if (blockPages) {
        this.tabs = this.sortTabs(blockPages).filter((tab) => {
          if (tab.pageTypeType !== this.pageTypes.VIDEOS) {
            return true;
          }

          return tab.blockVideos.length >= 1;
        });
      }

      if (finishFamilies) {
        this.finishFamilies = finishFamilies;

        this.finishDownloadsTab = this.findDownloadsTab(blockPages) ?? null;
        if (!this.finishDownloadsTab) {
          this.finishDownloadsTab = {
            id: 'downloads',
            order: Infinity,
            blockContents: [],
            blockPageFiles: [],
            blockVideos: [],
            pageTypeName: this.translateService.instant('CATALOGUE.INFO_BLOCKS.DOWNLOADS'),
            pageTypeType: UIInfoBlockTabsEnum.DOWNLOADS
          };
          this.tabs.push(this.finishDownloadsTab);
        }
      }

      if (finishes) {
        this.finishes = finishes;

        this.tabs.push({
          id: 'finishes',
          order: Infinity,
          blockContents: [],
          blockPageFiles: [],
          blockVideos: [],
          pageTypeName: this.translateService.instant('CATALOGUE.INFO_BLOCKS.FINISHES'),
          pageTypeType: UIInfoBlockTabsEnum.FINISHES
        } as UIBlockPageInterface)

        this.activeTab = this.tabs[this.tabs.length - 1];
      }

      this.switchTab(this.tabs[0]);
      this.changeDetector.markForCheck();
    });
  }

  switchTab(tab: BlockPageInterface | UIBlockPageInterface): void {
    this.activeTab = tab;
    this.activeTabImages = this.activeTab?.blockPageFiles.map(({ file }) => file) ?? [];
  }

  private sortTabs(tabs: BlockPageInterface[]): BlockPageInterface[] {
    return tabs.sort((a, b) => {
      if (a.pageTypeType === InfoBlockTabsEnum.VIDEOS) {
        return -1;
      }

      if (b.pageTypeType === InfoBlockTabsEnum.VIDEOS) {
        return 1;
      }
    
      return a.order - b.order;
    });
  }

  private findDownloadsTab(blockPages: BlockPageInterface[] | null): BlockPageInterface | null {
    return blockPages?.find(blockPage => blockPage.pageTypeType === InfoBlockTabsEnum.DOWNLOADS) ?? null;
  }
  
}
