<form [formGroup]="form" class="overflow-auto" #formEl>
  <h4 class="fw-bold color-gray mb-3 pb-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.PROPERTIES' | translate }}
  </h4>

  <div class="mb-3">
    <label for="inputCategory" class="form-label">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.CATEGORY' | translate | requiredFieldsAsterisk: requiredFields:'category') }}
    </label>
    <input type="text" class="form-control" id="inputCategory"
           formControlName="category"
           [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.CUSTOM_CATEGORY' | translate"
           [class.is-invalid]="form.controls.category.touched && form.controls.category.invalid"
           data-testid="categoryField"
    />
    <div class="invalid-feedback">
      {{ form.controls.category.errors | translateValidationErrors }}
    </div>
  </div>

  <div class="mb-3">
    <label for="inputCode" class="form-label">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.PRODUCT_CODE' | translate | requiredFieldsAsterisk: requiredFields:'code') }}
    </label>
    <input type="text" class="form-control" id="inputCode"
           formControlName="code"
           [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.PRODUCT_CODE' | translate"
           [class.is-invalid]="form.controls.code.touched && form.controls.code.invalid"
           data-testid="codeField"
    />
    <div class="invalid-feedback">
      {{ form.controls.code.errors | translateValidationErrors }}
    </div>
  </div>

  <div class="mb-3">
    <label for="inputTypeInformation" class="form-label">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.MATERIALS' | translate | requiredFieldsAsterisk: requiredFields:'typeInformation') }}
    </label>
    <input type="text" class="form-control" id="inputTypeInformation"
           formControlName="typeInformation"
           [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.MATERIALS' | translate"
           [class.is-invalid]="form.controls.typeInformation.touched && form.controls.typeInformation.invalid"
            data-testid="typeInformationField"
    />
    <div class="invalid-feedback">
      {{ form.controls.typeInformation.errors | translateValidationErrors }}
    </div>
  </div>

  <h4 class="fw-bold color-gray my-3 py-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.PRICES' | translate }}
  </h4>

  <div class="mb-3 row g-0">
    <label for="inputPricelistPrice" class="col-6 col-form-label text-end pe-4">
      {{ 'ORDERS.NON_STANDARD_ITEM_MODAL.FORM.SALES_PRICE' | translate | requiredFieldsAsterisk: requiredFields:'pricelistPrice' }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputPricelistPrice"
             formControlName="pricelistPrice"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.SALES_PRICE' | translate"
             [class.is-invalid]="form.controls.pricelistPrice.touched && form.controls.pricelistPrice.invalid"
             (blur)="formatPrice('pricelistPrice')"
             data-testid="pricelistPriceField"
      />
      <div class="invalid-feedback">
        {{ form.controls.pricelistPrice.errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <h4 class="fw-bold color-gray my-3 py-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.DIMENSIONS' | translate }}
  </h4>

  <div class="mb-3 row g-0">
    <label for="inputHeight" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.HEIGHT' | translate | requiredFieldsAsterisk: requiredFields:'height') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputHeight"
             formControlName="height"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.HEIGHT' | translate"
             [class.is-invalid]="form.controls.height.touched && form.controls.height.invalid"
             data-testid="heightField"
      />
      <div class="invalid-feedback text-end">
        {{ form.controls.height.errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputDepth" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.DEPTH' | translate | requiredFieldsAsterisk: requiredFields:'depth') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputDepth"
             formControlName="depth"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.DEPTH' | translate"
             [class.is-invalid]="form.controls.depth.touched && form.controls.depth.invalid"
             data-testid="depthField"
      />
      <div class="invalid-feedback text-end">
        {{ form.controls.depth.errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputWidth" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.WIDTH' | translate | requiredFieldsAsterisk: requiredFields:'width') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputWidth"
             formControlName="width"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.WIDTH' | translate"
             [class.is-invalid]="form.controls.width.touched && form.controls.width.invalid"
             data-testid="widthField"
      />
      <div class="invalid-feedback text-end">
        {{ form.controls.width.errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputVolume" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.VOLUME' | translate | requiredFieldsAsterisk: requiredFields:'volume') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputVolume"
             formControlName="volume"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.VOLUME' | translate"
             [class.is-invalid]="form.controls.volume.touched && form.controls.volume.invalid"
             (blur)="formatFloatNumber('volume')"
             data-testid="volumeField"
      />
      <div class="invalid-feedback text-end">
        {{ form.controls.volume.errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputWeight" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.WEIGHT' | translate | requiredFieldsAsterisk: requiredFields:'weight') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputWeight"
             formControlName="weight"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.WEIGHT' | translate"
             [class.is-invalid]="form.controls.weight.touched && form.controls.weight.invalid"
             (blur)="formatFloatNumber('weight')"
             data-testid="weightField"
      />
      <div class="invalid-feedback text-end">
        {{ form.controls.weight.errors | translateValidationErrors }}
      </div>
    </div>
  </div>
</form>
