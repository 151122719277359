<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex align-items-center p-2 small">
    <i class="material-image rounded-1 me-2"
      [style.background-image]="r.img ? 'url(' + r.img + ')' : 'url(/assets/icons/icon-material-placeholder-small.svg)'">
    </i>
    <ngb-highlight class="text-truncate" [result]="r.translation || r.code" [term]="t"></ngb-highlight>
  </div>
</ng-template>

<div
  *ngIf="!listOnly"
  class="position-relative"
  [class.mb-2]="selectedObjects.length"
>
  <label [for]="name" class="form-label">{{ text || '' }}</label>
  <input type="text" class="form-control"
         #input
         #instance="ngbTypeahead"
         [id]="name"
         [name]="name || ''"
         [placeholder]="placeholder || ''"
         [ngbTypeahead]="search"
         [editable]="false"
         [disabled]="isDisabled"
         [resultTemplate]="rt"
         [focusFirst]="true"
         [popupClass]="'material-typeahead'"
         [placement]="'bottom'"
         (blur)="onTouchedCallback()"
         (selectItem)="itemSelected($event, input)"
         (keydown.enter)="preventItemRemovalOnEnter($event)"
         data-testid="input"
         [ngClass]="inputFieldClass"
  />
</div>

<div class="material-selection" *ngIf="selectedObjects.length">
  <div class="d-inline-flex align-items-center
    bg-gray-200
    border border-color-gray-400 rounded-1
    p-1 me-2 mb-2"
       *ngFor="let material of selectedObjects"
       [title]="material.translation || material.code"
  >
    <span class="material-image rounded-1 me-2"
      [style.background-image]="material.img ? 'url(' + material.img + ')' : 'url(/assets/icons/icon-material-placeholder-small.svg)'">
    </span>
    <span class="text-truncate small">{{ material.translation || material.code }}</span>
    <button class="btn btn-default btn-xs color-gray d-inline-flex p-0 ms-2"
            *ngIf="!disabled && !listOnly"
            (click)="removeSelectedItem($event, material)"
            tabindex="-1"
            data-testid="removeButton"
    ><i class="icon ni-close"></i>
    </button>
  </div>
</div>
