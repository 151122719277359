<app-generic-modal [closable]="false" [cssClasses]="'h-100 d-flex flex-column'" [bodyClasses]="'overflow-auto'">
  <ng-template appModalHeader>
    <h2 class="m-0 me-4">
      {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.TITLE' | translate }}
    </h2>
    <app-ui-elements-close-button *ngIf="!loading" class="ms-auto" (click)="onClose()"></app-ui-elements-close-button>
  </ng-template>

  <ng-template appModalBody>
    <app-loader *ngIf="loading" [loading]="loading"></app-loader>

    <ng-container *ngIf="!loading">
      <form [formGroup]="form" class="h-100">
      <div class="row mb-4">
        <div class="mb-2 color-gray">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.PARTS_INFO_TEXT' | translate }}
        </div>
        <app-price-request-item-articles-table [articles]="item.articles"></app-price-request-item-articles-table>
      </div>
      <div class="mb-3 color-gray">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.MODIFICATIONS_INFO_TEXT' |
        translate }}</div>
      <div class="row mb-4">
        <div class="form-group col-6">
          <input
            id="based-on-standard"
            data-testid="titleField"
            formControlName="title"
            name="title"
            class="form-control fw-bold"
            [class.is-invalid]="form.controls.title.touched && form.controls.title.invalid"
            type="text"
            [placeholder]="('INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.TITLE_PLACEHOLDER' | translate) + ' *'"
          />
          <div class="invalid-feedback">
            {{ form.controls.title.errors | translateValidationErrors }}
          </div>
        </div>
        <div class="form-group col-6">
          <div class="d-flex gap-2">
            <label class="form-label m-0 mt-2" for="based-on-standard-item-quantity">
              {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.QUANTITY' | translate }} *
            </label>
            <div>
              <input id="based-on-standard-item-quantity" formControlName="quantity" name="quantity" class="form-control w-6r"
                [class.is-invalid]="form.controls.quantity.touched && form.controls.quantity.invalid" type="number" data-testid="quantityField"
                min="1"
                [placeholder]="'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.QUANTITY_PLACEHOLDER' | translate"
                />
              <div class="invalid-feedback">
                {{ form.controls.quantity.errors | translateValidationErrors }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 form-group d-flex flex-column justify-content-end mb-4">
        <label class="form-label" for="based-on-standard-item-description">
          {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.DESCRIPTION' | translate }} *
        </label>
        <textarea
          id="based-on-standard-item-description"
          data-testid="descriptionField"
          formControlName="description"
          name="description"
          class="form-control h-12.5r"
          [class.is-invalid]="form.controls.description.touched && form.controls.description.invalid"
          [placeholder]="'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.DESCRIPTION_PLACEHOLDER' | translate"
          ></textarea>
        <div class="invalid-feedback">
          {{ form.controls.description.errors | translateValidationErrors }}
        </div>
        <div class="mt-1 small color-gray">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.DESCRIPTION_INFO_TEXT' | translate }}</div>
      </div>

      <div class="row mb-4">
        <div class="form-group col-6">
          <label class="form-label" for="based-on-standard-item-dimensions">
            {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.DIMENSIONS' | translate }}
          </label>
          <textarea
            id="based-on-standard-item-dimensions"
            data-testid="dimensionsField"
            formControlName="dimensions"
            name="dimensions"
            class="form-control h-5r"
            [placeholder]="'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.DIMENSIONS_PLACEHOLDER' | translate"
            [class.is-invalid]="form.controls.dimensions.touched && form.controls.dimensions.invalid"
            ></textarea>
          <div class="invalid-feedback">
            {{ form.controls.dimensions.errors | translateValidationErrors }}
          </div>
          <div class="mt-1 small color-gray">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.DIMENSIONS_INFO_TEXT' | translate }}</div>
        </div>
        <div class="form-group col-6">
          <app-order-article-material-input
            id="based-on-standard-item-finishes"
            data-testid="finishesField"
            formControlName="finishes"
            name="finishes"
            [inputFieldClass]="form.controls.finishes.touched && form.controls.finishes.invalid ? 'is-invalid' : ''"
            [placeholder]="'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.FINISHES_PLACEHOLDER' | translate"
            [text]="('INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.FINISHES' | translate) + ' *'"
          />
          <div class="invalid-feedback" [class.d-block]="form.controls.finishes.touched && form.controls.finishes.invalid">
            {{ form.controls.finishes.errors | translateValidationErrors }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label" for="based-on-standard-item-files">
          {{ 'INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.ATTACHMENTS' | translate }}
        </label>
        <app-price-request-files-input [priceRequestId]="priceRequestId" [priceRequestItemId]="item.id"
          id="based-on-standard-item-files" formControlName="customMadePriceRequestItemFiles"
          name="customMadePriceRequestItemFiles" acceptedFiles=".jpg,.jpeg,.png,.pdf,.dwg,.eps,.xlsx,.xls,.ods,.numbers,.pptx,.ppt,.cdr"></app-price-request-files-input>
      </div>
    </form>
    </ng-container>
  </ng-template>

  <ng-template appModalFooter>
    <button
      type="button"
      class="btn btn-outline btn-lg w-11.875r d-flex align-items-center justify-content-center h-100 no-outline"
      (click)="onDiscard()"
      [disabled]="loading"
    >
      {{ 'INQUIRIES.PRICE_REQUESTS.ACTIONS.DISCARD' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-primary btn-lg w-11.875r d-flex align-items-center justify-content-center h-100 no-outline"
      [disabled]="isUploadingFiles || loading || isSaving"
      (click)="onSave()"
      data-testid="saveButton"
    >
      {{ 'INQUIRIES.PRICE_REQUESTS.ACTIONS.SAVE' | translate }}
    </button>
  </ng-template>
</app-generic-modal>
