<app-loader #loader cssClasses="loader--white"></app-loader>

<form [formGroup]="form" (ngSubmit)="save()" class="d-flex flex-column overflow-hidden">
  <div class="modal-header flex-column align-items-stretch p-0">
    <div class="px-4 py-3 border-bottom">
      <h1 class="mb-0">{{ 'PROFILE.COMPLETE_YOUR_PROFILE' | translate }}</h1>
    </div>
    <div class="px-3">
      <ul class="nav nav-pills w-100">
        <li class="nav-item w-50">
          <a class="nav-link"
            [class.active]="modalTabs.PROFILE === currentTab"
            (click)="changeTab(modalTabs.PROFILE)"
          >1. {{ 'PROFILE.PROFILE_INFORMATION' | translate }}</a>
        </li>
        <li class="nav-item w-50">
          <a class="nav-link"
            [class.active]="modalTabs.PASSWORD === currentTab"
            [class.disabled]="profileForm.invalid"
            (click)="changeTab(modalTabs.PASSWORD)"
          >2. {{ 'PROFILE.CREATE_PASSWORD' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
  <ng-container [ngSwitch]="currentTab">
    <!-- Profile tab -->
    <ng-container *ngSwitchCase="modalTabs.PROFILE">
      <div class="modal-body p-0">
        <div class="px-4 py-4">
          <app-file-upload
            [showUploadLinkOnly]="true"
            previewClass="mb-2"
            imgClass="rounded-circle sp-60 me-2"
            [fallback]="{
              default: 'assets/images/user/placeholder.svg',
              half: 'assets/images/user/placeholder.svg',
              double: 'assets/images/user/placeholder.svg',
              triple: 'assets/images/user/placeholder.svg'
            }"
            [labels]="{ empty: 'SETTINGS.PROFILE.PHOTO.UPLOAD' | translate, selected: 'SETTINGS.PROFILE.PHOTO.CHANGE' | translate }"
            (selected)="onProfilePictureChange($event)"
            [fileUrl]="user?.profilePicture?.url"
          >
          </app-file-upload>
          <div *ngIf="pictureErrors && pictureErrors.length > 0"
            class="text-danger small"
          >
            <div class="pb-2" *ngFor="let error of pictureErrors">
              {{ error | translate }}
            </div>
          </div>
          <app-profile-form [profileForm]="profileForm" [countries]="countries$ | async" [user]="user" [inModal]="true"></app-profile-form>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-lg btn-primary rounded-0 col-12 col-sm-6"
          (click)="changeTab(modalTabs.PASSWORD)"
          type="button"
          data-testid="continueBtn"
        >{{ 'PROFILE.CONTINUE' | translate}}</button>
      </div>
    </ng-container>

    <!-- Password tab -->
    <ng-container *ngSwitchCase="modalTabs.PASSWORD">
      <div class="modal-body p-0">
        <div class="d-none">
          <!-- Hidden email field allows password managers to save both email and password -->
          <input id="email" type="text" [value]="user.email" autocomplete="username" disabled>
        </div>

        <div class="px-4 px-0">
          <div class="row d-flex flex-column flex-sm-row px-0 px-sm-4 pt-4 mb-0">
            <label class="col-6 col-form-label text-nowrap text-sm-end pe-3">{{ 'AUTH.RESET_PASSWORD.ENTER_NEW_PASSWORD' | translate }}</label>
            <div class="col-12 col-sm-6" data-testid="passwordInputContainer">
              <app-password-input
                [id]="'new-password'"
                [formControl]="passwordControl"
                data-testid="passwordInput"
              ></app-password-input>
              <div class="invalid-feedback" [class.d-block]="passwordControl.touched && passwordControl.invalid">
                {{ passwordControl.errors | translateValidationErrors }}
              </div>
            </div>
          </div>
          <div class="row small pb-4">
            <div class="col-12 col-sm-6 offset-sm-6">
              <div *ngIf="passwordControl.errors && passwordControl.errors['isEqualTo']" class="color-danger mt-2">
                {{ 'PASSWORD.PASSWORD_REQUIREMENTS.DONT_USE_EXAMPLE' | translate }}
              </div>
              <app-password-strength-indicator *ngIf="passwordControl.errors && !passwordControl.errors['required'] || !passwordControl.errors"
                class="d-block mt-2"
                [control]="passwordControl"
              ></app-password-strength-indicator>
            </div>
          </div>
          <hr class="mt-0">
          <div class="d-flex flex-column flex-sm-row pb-2">
            <div class="col-sm-6 order-2 order-sm-1" data-testid="passwordInfo">
              <p class="small">{{ 'PASSWORD.UNIQUE_AND_UNGUESSABLE' | translate }}</p>
              <p class="small">{{ 'PASSWORD.EXAMPLE' | translate }}</p>
            </div>
            <div class="col-sm-6 color-gray-900 small order-1 order-sm-2" data-testid="passwordErrors">
              <app-password-requirement-indicator [validationErrors]="passwordControl.errors"></app-password-requirement-indicator>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-lg btn-primary rounded-0 col-12 col-sm-6"
          type="submit"
          data-testid="saveBtn"
        >{{ 'PROFILE.SAVE' | translate }}</button>
      </div>
    </ng-container>

  </ng-container>
</form>
