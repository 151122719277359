<app-generic-modal [closable]="false" cssClasses="d-flex flex-column mh-90vh" bodyClasses="p-0 bg-gray-300">
  <ng-template appModalHeader>
    <div class="d-flex flex-column w-100">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="m-0">
          {{ 'INQUIRIES.SHARE_INQUIRY.TITLE' | translate }}
        </h2>
        <app-ui-elements-close-button (click)="onCancel()"></app-ui-elements-close-button>
      </div>

      <p class="m-0 mt-2 pe-4 color-gray-600">
        {{ 'INQUIRIES.SHARE_INQUIRY.SECONDARY_TITLE' | translate }}
      </p>
    </div>
  </ng-template>

  <ng-template appModalBody>
    <div *ngIf="shouldDisplayInfoText" class="d-flex align-items-center bg-gray-400 px-4 py-3">
      <i class="icon ni-info color-gray-900"></i>
      <p class="m-0 ms-2 small color-gray-900">{{ infoTextTranslationKey | translate }}.</p>
    </div>

    <form [formGroup]="form" class="p-4 bg-white">
      <label>{{ 'INQUIRIES.SHARE_INQUIRY.ENTER_EMAIL' | translate }}</label>

      <input
        id="shareInquiryEmail"
        class="form-control mt-3"
        formControlName="email"
        [class.is-invalid]="form.controls.email.touched && form.controls.email.invalid"
        data-testid="emailInput"
      />

      <div class="invalid-feedback">
        {{ emailCustomError ? emailCustomError : (form.controls.email.errors | translateValidationErrors) }}
      </div>

      <button
        (click)="onAdd()"
        class="add-btn btn btn-primary no-outline mt-2"
        data-testid="addButton"
      >
        {{ 'ACTIONS.ADD' | translate }}
      </button>

      <div
        class="text-danger text-small mt-3"
        [class.d-none]="!isSubmitted || usersToBeSharedWith.length"
        [class.d-block]="!usersToBeSharedWith.length && isSubmitted"
      >
        {{ 'INQUIRIES.SHARE_INQUIRY.AT_LEAST_ONE_EMAIL_REQUIRED' | translate }}
      </div>
    </form>

    <div class="p-4 border fw-bold color-gray-600 bg-white">{{ 'INQUIRIES.SHARE_INQUIRY.ADDED_PEOPLE' | translate }}:</div>

    <app-loader *ngIf="loading" [loading]="loading" cssClasses="loader--relative mt-5"></app-loader>

    <ng-container *ngIf="!loading">
      <div *ngIf="!users.length" class="pt-4r text-center color-gray-600">{{ 'INQUIRIES.SHARE_INQUIRY.NO_DATA' | translate }}.</div>

      <div
        class="user d-flex justify-content-between align-items-center px-2r py-3 bg-gray-100 border-bottom deep-hover-d-flex"
        *ngFor="let user of users"
        data-testid="user"
      >
        <div class="d-flex">
          <img
            [src]="user?.profilePicture?.url ?? '/assets/images/user/user@1,5x.svg'"
            width="48"
            height="48"
            alt="avatar"
            class="rounded-circle object-fit-cover"
            draggable="false"
          />

          <div class="d-flex flex-column justify-content-around ms-3 text-break--all">
            <span class="fw-bold color-gray-700">{{ user.firstName + ' ' + user.lastName }}</span>
            <span class="small color-gray-700">{{ user.email }}</span>
          </div>
        </div>

        <button
          *ngIf="usersToBeSharedWith.includes(user)"
          (click)="onRemoveUserFromList(user)"
          class="justify-content-center align-items-center btn btn-danger no-outline d-none deep-hover border-radius-0125r w-2r h-2r"
          data-testid="removeButton"
        >
          <i class="icon ni-trash"></i>
        </button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template appModalFooter>
    <button 
      type="button"
      class="btn btn-outline btn-lg w-11.875r no-outline hover-color-black hover-bg-gray-200"
      (click)="onCancel()"
      [disabled]="isSubmitting">
      {{ 'ACTIONS.CANCEL' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-primary btn-lg w-11.875r no-outline"
      (click)="onSubmit()"
      [disabled]="isSubmitting"
      data-testid="submitButton"
    >
      {{ 'ACTIONS.SAVE' | translate }}
    </button>
  </ng-template>
</app-generic-modal>
