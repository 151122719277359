<div class="bg-gray-200">
  <div *ngIf="limitedView" class="d-flex p-3 color-gray-600">
    <div class="preview-img me-2" [appHideByFieldType]="fieldTypes.IMG">
      <app-row-image [imageFile]="imageFile" [defaultIcon]="icon"></app-row-image>
    </div>
    <div class="w-auto ms-1">
      <span class="h5 mb-0 fw-bold" [appHideByFieldType]="fieldTypes.SYSTEM">{{ priceRequestItem.title }}</span>
      <p class="m-0 small color-gray-600 fw-bold" [class.d-none]="row.belongsToLockedGroup">
        {{ 'INQUIRIES.PRICE_REQUESTS.ITEMS_LIST.CUSTOM_MADE_FURNITURE' | translate }}
      </p>
      <p class="m-0 small text-break--all" [appHideByFieldType]="fieldTypes.SHORT_TEXT">
        {{ description }}
      </p>
    </div>
  </div>

  <div *ngIf="!limitedView" class="list-row row deep-hover-color-gray-400 bg-gray-200 m-0"
    [ngClass]="{ 'hover-bg-white': !row.belongsToLockedGroup, 'list-row--locked': row.belongsToLockedGroup }"
    (click)="onToggleSelect()">
    <div class="col-12 d-flex p-0">
      <!-- drag handle -->
      <button class="btn btn-link d-flex color-gray-200 deep-hover drag-handle m-0 py-3 px-1"
        [class.invisible]="row.belongsToLockedGroup || isFilterEnabled" appDragHandle appClickStopPropagation>
        <i class="icon ni-reorder"></i>
      </button>
      <!-- / drag handle -->

      <div class="first-column">
        <!-- placeholder which will get some space in nested levels -->
      </div>

      <!-- checkbox col -->
      <div class="checkbox-col py-3">
        <app-check-mark
          class="checkbox"
          [class.invisible]="row.belongsToLockedGroup"
          [name]="row.id"
          [value]="selected"
          (changed)="onSelect($event)"
          appClickStopPropagation
        ></app-check-mark>
      </div>
      <!-- / checkbox col -->

      <!-- img col -->
      <div class="image-col ps-2 py-3" [appHideByFieldType]="fieldTypes.IMG">
        <app-row-image [imageFile]="imageFile" [defaultIcon]="icon"></app-row-image>
      </div>
      <!-- / img col -->

      <!-- description col -->
      <div class="ps-2 py-3 flex-grow-1 flex-shrink-1">
        <span class="color-black fw-bold" [appHideByFieldType]="fieldTypes.SYSTEM">{{ priceRequestItem.title }}</span>
        <div [class]="shouldDisplayStatusBadge() ? 'mt-1 mb-2' : ''">
          <span *ngIf="shouldDisplayStatusBadge()"
            class="px-3 py-1 me-2 rounded-pill bg-gray-600 color-gray-100 small fw-bold text-nowrap" data-testid="statusBadge">
            {{ statusTranslations[priceRequestItem.status] | translate }}
          </span>
          <span class="small color-gray-600 fw-bold" [class.d-none]="row.belongsToLockedGroup">{{
            'INQUIRIES.PRICE_REQUESTS.ITEMS_LIST.CUSTOM_MADE_FURNITURE' | translate }}</span>
        </div>

        <div class="d-flex align-items-center">
          <ng-container [ngSwitch]="orderState">
            <ng-container *ngSwitchCase="orderStates.DRAFT">
              <app-price-request-item-status-icon
                *ngIf="this.isDealer && !isCanceledOrExpired"
                class="mt-1"
                [item]="priceRequestItem"
                [locatedInsideOffer]="true"
                (clarificationCanceled)="onCancelClarification($event)"
                (clarificationSubmitted)="onSubmitClarification()"
              ></app-price-request-item-status-icon>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <app-price-request-item-product-code-icon
                class="mt-1"
                [code]="row.fullCode"
                [isDealer]="isDealer"
                [orderState]="orderState"
              ></app-price-request-item-product-code-icon>
            </ng-container>
          </ng-container>
          <button
            class="btn p-0"
            [class.color-primary]="!this.isDealer && priceRequestItem.jiraIssueKey"
            (click)="onOpenPriceRequestInSD($event)"
          >
            ID: {{ priceRequestItem.jiraIssueKey }}
          </button>
        </div>

        <div
          *ngIf="row.fullCode"
          class="small color-gray-700 text-break--all my-1"
          [title]="row.fullCode"
          [appHideByFieldType]="fieldTypes.CODE"
          data-testid="productCode"
        >
          {{ row.fullCode }}
        </div>

        <div
          class="small color-gray-700 text-break--all"
          [title]="description"
          [appHideByFieldType]="fieldTypes.SHORT_TEXT"
        >
          {{ description }}
        </div>
      </div>
      <!-- / description col -->

      <!-- materials col -->
      <div
        *ngIf="isColumnsVisible([fieldTypes.TYPE, fieldTypes.ORDER_ARTICLE_MATERIALS, fieldTypes.ORDER_ARTICLE_MATERIALS_INLINE])"
        class="ps-2 materials flex-shrink-0"
        [style.width.rem]="columns.materialsAndType.width"
      >
        <div class="d-flex py-3 flex-column" [appHideByFieldType]="fieldTypes.ORDER_ARTICLE_MATERIALS">
          <app-materials-list
            [materials]="rowMaterials"
            [showMaterialTitle]="fieldsToShow.includes(fieldTypes.ORDER_ARTICLE_MATERIALS_INLINE)"
          ></app-materials-list>
        </div>
      </div>
      <!-- / materials col -->

      <!-- details col -->
      <div
        *ngIf="isColumnsVisible([fieldTypes.DIMENSIONS, fieldTypes.WEIGHT, fieldTypes.VOLUME])"
        class="details pe-2 py-3 flex-shrink-0 small"
        [style.width.rem]="columns.details.width"
      >
        <span [appHideByFieldType]="fieldTypes.DIMENSIONS" class="text-break max-text-lines-3">
          {{ priceRequestItem.dimensions }}
        </span>
      </div>

      <!-- quantity col -->
      <div class="quantity text-end pe-2 py-3 flex-shrink-0" [style.width.rem]="columns.quantity.width">
        <span class="small color-gray-900">
          {{ row.quantity }}
        </span>
      </div>
      <!-- / quantity col -->

      <!-- single price col -->
      <div class="single-price text-end py-3 pe-2 flex-shrink-0"
           [class.active-mode--sale]="selectedMode === saleMode.SALE"
           [style.width.rem]="columns.price.width">
        <span class="small color-gray-900">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ (selectedMode === saleMode.SALE ? row.formattedConfigurationSalePrice : row.formattedConfigurationPurchasePrice) | priceFormat: { zeroReplacement: 'N/A' } }}
          </visibility-by-variables>
        </span>
      </div>
      <!-- / single price col -->

      <!-- discount col -->
      <div
        *ngIf="hasDiscountsInPurchaseMode || selectedMode === saleMode.SALE"
        class="text-end flex-shrink-0 ps-0 pe-2 py-3"
        [class.active-mode--sale]="selectedMode === saleMode.SALE"
        [appHideByFieldType]="fieldTypes.DISCOUNT"
        [style.width.rem]="columns.discount.width"
        data-testid="discountColumn"
      >
        <span class="color-gray-900 small">
          {{ (selectedMode === saleMode.SALE ? row.formattedSaleDiscount : row.formattedDiscount) | discount }}
        </span>
      </div>
      <!-- / discount col -->

      <!-- total price col -->
      <div class="total-price text-end py-3 flex-shrink-0"
           [class.active-mode--sale]="selectedMode === saleMode.SALE"
           [style.width.rem]="columns.totalPrice.width">
        <span class="small color-gray-900">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ row.formattedTotalConfigurationPrice | priceFormat: { zeroReplacement: 'N/A' } }}
          </visibility-by-variables>
        </span>
      </div>
      <!-- / total price col -->

      <!-- row actions col -->
      <div class="actions px-3 py-3 flex-shrink-0"
           [class.active-mode--sale]="selectedMode === saleMode.SALE"
           [style.width.rem]="columns.actions.width">
        <div ngbDropdown container="body" class="btn-group"
             *ngIf="orderState === orderStates.AX_ARCHIVE_CONFIRMED || selectedMode === saleMode.ORDER || isPmNarbutas; else pencil"
             [placement]="['bottom-end', 'top-end']"
             appClickStopPropagation>
          <button ngbDropdownToggle class="bg-gray-300 hover-bg-gray-400 color-gray-700 btn no-focus d-flex p-1" id="itemActionsDropdown">
            <i class="icon ni-more-horiz"></i>
          </button>
          <div
            ngbDropdownMenu
            class="dropdown-menu p-0 shadow"
            aria-labelledby="itemActionsDropdown"
            [style.max-width.px]="240"
          >
            <button
              *ngIf="!isPM"
              ngbDropdownItem
              class="d-flex align-items-center btn btn-secondary no-outline border-bottom"
              (click)="onOpenPriceRequest()"
            >
              <i class="icon ni-sticky-note me-2"></i>
              <span class="flex-1 text-white-space-normal">{{ 'ORDER_PRODUCTS_LIST.ROW_AND_GROUP_ACTIONS.OPEN_PRICE_REQUEST' | translate }}</span>
            </button>
            <button
              *ngIf="isPM"
              ngbDropdownItem
              class="d-flex align-items-center btn btn-secondary no-outline border-bottom"
              (click)="onOpenPriceRequestInSD($event)"
            >
              <i class="icon ni-sticky-note me-2"></i>
              <span class="flex-1 text-white-space-normal">{{ 'ORDER_PRODUCTS_LIST.ROW_AND_GROUP_ACTIONS.OPEN_PRICE_REQUEST_IN_SD' | translate }}</span>
            </button>
            <button ngbDropdownItem class="d-flex align-items-center btn btn-secondary no-outline"
              (click)="onReviewItem()">
              <i class="icon me-2" [ngClass]="icon"></i>
              <span class="flex-1 text-white-space-normal">{{ 'ORDER_PRODUCTS_LIST.ROW_AND_GROUP_ACTIONS.REVIEW_ITEM' | translate }}</span>
            </button>
          </div>
        </div>
        <ng-template #pencil>
          <button
            *ngIf="this.priceRequestItem.status != priceRequestItemStatuses.CANCELED"
            class="btn bg-gray-600 no-outline p-1 m-0 color-white hover-color-white hover-bg-gray-700 d-flex"
            (click)="onApplyCustomDiscount(row)"
            appClickStopPropagation
          >
            <i class="icon ni-create"></i>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
