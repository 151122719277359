<div class="d-flex px-5 py-4" [ngClass]="multipleVideos ? 'justify-content-between' : 'justify-content-center'">
  <div>
    <div class="video-player-size position-relative">
      <div
        class="video-player-size selected-video-image cursor-pointer position-absolute"
        [style.background-image]="
          'url(' + ((selectedVideo.image.url | resizeImage : imageSizes.SIZE_764x430 | uncache | async) ?? '') + ')'
        "
      ></div>
      <div
        class="video-player-size youtube-play-button-overlay position-absolute d-flex justify-content-center align-items-center cursor-pointer"
        (click)="onStartVideo()"
        data-testid="videoPlayerOverlay"
      >
        <div [inlineSVG]="'/assets/images/default-icons/youtube-play-button.svg'"></div>
      </div>

      <youtube-player
        #player
        class="position-absolute"
        [ngClass]="videoStarted ? 'player-visible' : 'player-hidden'"
        [videoId]="extractVideoId(selectedVideo.url)"
        width="764"
        height="430"
        (stateChange)="onPlayerStateChange($event)"
      ></youtube-player>
    </div>
    <p class="mb-0 mt-2 fw-bold color-gray-900">{{ selectedVideo.title }}</p>
  </div>

  <div *ngIf="multipleVideos" class="videos-container d-flex flex-column ms-4 overflow-auto" data-testid="videosSidebar">
    <div *ngFor="let video of videos" class="cursor-pointer mb-3" (click)="onSelectVideo(video)" data-testid="availableVideo">
      <div
        class="thumbnail-image"
        [style.background-image]="'url(' + ((video.image.url | resizeImage : imageSizes.SIZE_198x112 | uncache | async) ?? '') + ')'"
      ></div>
      <div class="thumbnail-text-container me-2">
        <p class="m-0 pt-0875r color-gray-900" [ngClass]="{ 'fw-bold': video.id === selectedVideo.id }">{{ video.title }}</p>
      </div>
    </div>
  </div>
</div>
