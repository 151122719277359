import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'translateValidationErrors',
  pure: false
})
export class TranslateValidationErrorsPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
  ) {
  }

  transform(errors: {}, glue: string = '. '): string {
    return Object.keys(errors ?? {})
      .map(key => {
        const params = errors[key];
        const translationKey = FormErrorsMap[key] ?? key;
        const translated = this.translateService.instant(
          `VALIDATION.${translationKey}`,
          typeof params === 'object' ? params : {}
        );

        return translated;
      })
      .join(glue);
  }
}

export const FormErrorsMap = {
  required: 'REQUIRED',
  noWhitespace: 'REQUIRED',
  onlyText: 'TEXT_ONLY',
  numbers: 'NUMBERS_ONLY',
  extendedNumbers: 'EXTENDED_NUMBERS',
  textAndNumbers: 'TEXT_AND_NUMBERS',
  textAndNumbersPostal: 'TEXT_AND_NUMBERS_POSTAL_CODE',
  textWithSeparators: 'TEXT_WITH_SEPARATORS',
  email: 'EMAIL',
  max: 'MAX_VALUE',
  min: 'MIN_VALUE',
  maxlength: 'CHARACTER_LIMIT',
  minlength: 'MIN_CHARACTER_LIMIT',
  alphanumericWithoutSpace: 'ALPHANUMERIC_WITHOUT_SPACE',
  decimalNumbers: 'DECIMAL_NUMBERS',
  dateFormat: 'INVALID_DATE_FORMAT',
  discount: 'DISCOUNT',
  greaterThan: 'GREATER_THAN',
  minDate: 'MIN_DATE',
  maxDate: 'MAX_DATE',
  startTimeNotAfterEndTime: 'START_TIME_NOT_AFTER_END_TIME',
  textAndDashes: 'TEXT_AND_DASHES',
  hasUpperCaseLetters: 'REQUIRES_UPPER_CASE',
  hasLowerCaseLetters: 'REQUIRES_LOWER_CASE',
  hasNumbers: 'REQUIRES_NUMBER',
  hasSpecialChars: 'REQUIRES_SPECIAL_CHARACTER',
  passwordMatch: 'PASSWORD_MATCH',
}
