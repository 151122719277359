import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { ImageSizeEnum } from '../../../shared/pipes/resize-image/resize-image.pipe';
import { BlockPageVideoInterface } from '../block-page.interface';

@Component({
  selector: 'app-block-page-videos',
  templateUrl: './block-page-videos.component.html',
  styleUrl: './block-page-videos.component.scss',
})
export class BlockPageVideosComponent implements OnInit {
  @Input() videos: BlockPageVideoInterface[];

  @ViewChild('player') player: YouTubePlayer;

  selectedVideo: BlockPageVideoInterface = null;
  videoStarted = false;
  atLeastOneVideoWasPlayed = false;

  loadingNewVideo = false;
  autoPlayVideoAfterLoadingIt = false;

  imageSizes = ImageSizeEnum;

  ngOnInit(): void {
    this.selectedVideo = this.videos[0];
  }

  onPlayerStateChange(event: YT.OnStateChangeEvent) {
    if (this.loadingNewVideo && event.data === YT.PlayerState.CUED) {
      this.loadingNewVideo = false;

      if (this.autoPlayVideoAfterLoadingIt) {
        this.autoPlayVideoAfterLoadingIt = false;
        this.playVideo();
      }
    }
  }

  onSelectVideo(video: BlockPageVideoInterface): void {
    if (video.id === this.selectedVideo.id) {
      return;
    }

    this.selectedVideo = video;
    this.videoStarted = false;
    this.loadingNewVideo = true;
  }

  onStartVideo(): void {
    if (this.loadingNewVideo && this.atLeastOneVideoWasPlayed) {
      this.autoPlayVideoAfterLoadingIt = true;

      return;
    }

    this.playVideo();
  }

  playVideo(): void {
    this.player.playVideo();

    if (this.atLeastOneVideoWasPlayed) {
      this.videoStarted = true;
    } else {
      // on first play video player takes a little bit of time to start playing video
      // so this gives a little bit of delay for cover image removal for better UX
      setTimeout(() => {
        this.videoStarted = true;
      }, 500);
    }

    this.atLeastOneVideoWasPlayed = true;
  }

  extractVideoId(url: BlockPageVideoInterface['url']): string {
    const params = new URL(url).searchParams;
    return params.get('v');
  }

  get multipleVideos(): boolean {
    return this.videos.length > 1;
  }
}
