import { InjectionToken } from '@angular/core';

export const LocalStorageKeys = {
  COMPATIBILITY_INFO_POPOVER_DISMISSED: 'compatibility_info_popover_dismissed',
  MIGRATIONS_INSTRUCTIONS_STORAGE_KEY: 'migrationsInstructions',
  MAINTENANCE_DISMISSED_UNTIL_KEY: 'maintenanceBannerDismissedUntil',
  PROJECT_RESERVATIONS_FILTERS: 'projectReservationsFilters',
  CMPR_FILTERS: 'cmrpFilters',
  CLAIMS_FILTERS: 'claimsFilters'
};

export const PERSIST_LOCAL_STORAGE_KEYS = new InjectionToken<string[]>('Local storage keys to persist after logout', {
  providedIn: 'root',
  factory: () => [
    LocalStorageKeys.COMPATIBILITY_INFO_POPOVER_DISMISSED,
    LocalStorageKeys.MIGRATIONS_INSTRUCTIONS_STORAGE_KEY,
    LocalStorageKeys.MAINTENANCE_DISMISSED_UNTIL_KEY,
  ],
});
