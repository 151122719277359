import {Component, ViewChild, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CountryService } from '../../core/services/country/country.service';
import { UserService } from '../../core/services/user/user.service';
import { UserInterface, UserUpdateInterface } from '../../core/models/user.model';
import { PASSWORD_VALIDATION } from '../../password/password-validation';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { ToastService } from '../../ui-elements/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { FileUploadData } from '../../ui-elements/file-upload/file-upload.component';
import { ProfileFormService } from '../profile-form/profile-form.service';

export enum ModalProfileTabs {
  PROFILE = 1,
  PASSWORD = 2
}

interface ErrorsResponseInterface {
  children: {
    [key: string]: {
      errors: string[];
    };
  };
}

@Component ({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
  providers: [ProfileFormService]
})
export class ProfileModalComponent implements AfterViewInit {
  @ViewChild('loader') private loader: LoaderComponent;

  modalTabs = ModalProfileTabs;
  currentTab = ModalProfileTabs.PROFILE;

  profileForm = this.profileFormService.getForm();
  passwordControl = new UntypedFormControl('', PASSWORD_VALIDATION);
  form: UntypedFormGroup = new UntypedFormGroup({
    profile: this.profileForm,
    password: this.passwordControl
  });

  user: UserInterface;
  countries$ = this.countryService.all();
  profilePicture: FileUploadData = null;
  pictureErrors: string[] = [];

  constructor(
    private modal: NgbActiveModal,
    private router: Router,
    private countryService: CountryService,
    private userService: UserService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private profileFormService: ProfileFormService
  ) { }

  ngAfterViewInit(): void {
    this.refresh();
  }

  refresh() {
    this.loader.show();
    this.cd.detectChanges();

    this.userService.fromStorage().subscribe(result => {
      this.user = result;

      this.profileForm.patchValue(result);
      this.profileForm.controls.country.setValue(this.user?.country?.id ?? null);
      this.loader.hide();
      this.cd.detectChanges();
    });
  }

  changeTab(tab: ModalProfileTabs) {
    // don't switch to password tab if profile info is not filled.
    if (tab === ModalProfileTabs.PASSWORD && this.form.controls.profile.invalid) {
      this.profileForm.markAllAsTouched();
      this.profileForm.updateValueAndValidity();
      this.scrollToFirstInvalidControl();
      return;
    }

    this.currentTab = tab;
  }

  save() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) {
      this.scrollToFirstInvalidControl();
      this.displayErrorMessage();
      return;
    }

    this.loader.show();
    this.saveUserInfo().pipe(
      switchMap((user) => !this.profilePicture ? of(user) : this.uploadProfilePicture())
    ).subscribe({
      next: (user) => {
        this.translateService.get('SETTINGS.PROFILE.SUCCESS').subscribe(translation => {
          this.toastService.success(translation);
          this.loader.hide();
          this.router.navigateByUrl('/');
          this.modal.close();
        });
      },
      error: error => {
        this.loader.hide();
      }
    });
  }

  private saveUserInfo(): Observable<UserInterface> {
    const updatedUserInfo = {
      ...this.profileForm.getRawValue(),
      ...{ plainPassword: this.passwordControl.value }
    } as UserUpdateInterface;
    return this.userService.update(this.user.id, updatedUserInfo);
  }

  private displayErrorMessage() {
    this.translateService.get('SETTINGS.PROFILE.FAILURE').subscribe(translation => {
      this.toastService.danger(translation);
    });
  }

  private scrollToFirstInvalidControl() {
    setTimeout(() => {
      const invalidElement = document.querySelector('.is-invalid');
      
      if (invalidElement) {
        invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  }

  onProfilePictureChange(event: FileUploadData) {
    this.profilePicture = event;
  }

  uploadProfilePicture() {
    if (!this.profilePicture) {
      return null;
    }
    return this.userService.uploadProfilePicture(this.user.id, this.profilePicture.file).pipe(
      catchError(({ error: { errors } }: { error: { errors: ErrorsResponseInterface} }) => {
        const profilePicture = errors.children.profilePicture;

        if (profilePicture.errors) {
          this.pictureErrors = profilePicture.errors.map((errorCode: string) => {
            return `SETTINGS.PROFILE.ERRORS.${errorCode}`;
          });
          this.currentTab = this.modalTabs.PROFILE;
        }
        return throwError(() => ({
          ...this.user,
          profilePicture: this.user.profilePicture
            ? { ...this.user.profilePicture, url: `${this.user.profilePicture.url}?date=${Date.now()}` }
            : null,
        }));
      })
    );
  }
}
