<app-language-switch 
  class="position-absolute top-0 end-0 mt-2 me-5"
  toggleClass="btn text-uppercase no-outline dark-color-mode"
></app-language-switch>
<div class="maintenance">
  <div class="content-wrapper mx-auto">
    <div class="logo d-block mb-2" app-logo></div>
    <div class="hero-image my-4"></div>
    <h2 class="mb-2">{{ 'MAINTENANCE.TITLE' | translate }}</h2>
    <div class="d-flex flex-column gap-3">
      <span>{{ 'MAINTENANCE.TEXT' | translate }}</span>
      <div *ngIf="until">
        <div>{{ 'MAINTENANCE.UNTIL' | translate }}</div>
        <div class="fw-bold">{{until | date: 'yyyy-MM-dd HH:mm'}} <span>{{ 'MAINTENANCE.LOCAL_TIME' | translate }}</span>.</div>
      </div>
      <span>{{ 'MAINTENANCE.FOOTNOTE' | translate }}</span>
    </div>
  </div>
</div>
