<div
  *ngIf="code || (isDealer && orderState === OrderStates.WAITING); else missingCode"
  class="me-1"
>
  <i class="icon ni-tick-circle color-success" data-testid="checkMarkIcon"></i>
</div>

<ng-template #missingCode>
  <div
    class="me-1"
    [ngbTooltip]="tooltipContent"
    placement="right"
    container="body"
  >
    <i class="icon ni-warning-circle-fill color-danger" data-testid="warningIcon"></i>
  </div>
</ng-template>

<ng-template #tooltipContent>
  <div class="text-pre-wrap">
    {{ 'INQUIRIES.PRICE_REQUESTS.PRICE_REQUEST_ITEM.POPOVERS.PRODUCT_CODE_MISSING' | translate }}
  </div>
</ng-template>