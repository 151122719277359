<table class="w-100">
    <thead class="border-0 border-bottom border-2">
      <tr class="row m-0 py-2 color-gray">
        <th class="col-6 ps-1 pe-0">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.STANDARD_ITEM_PARTS' | translate | uppercase }}</th>
        <th class="w-12.5p">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.CODE' | translate | uppercase }}</th>
        <th class="w-12.5p">{{ 'INQUIRIES.PRICE_REQUESTS.MODALS.ITEM_PREVIEW.FINISHES' | translate | uppercase }}</th>
        <th class="w-12.5p">{{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.DIMENSIONS' | translate | uppercase }}</th>
        <th class="w-12.5p pe-1 text-end">{{ 'INQUIRIES.PRICE_REQUESTS.NEW_ITEM.QUANTITY' | translate | uppercase }}</th>
      </tr> 
    </thead>
    <tbody>
      <tr class="row py-1 m-0 border-bottom color-black" *ngFor="let article of articles">
        <td class="col-6 ps-1 pe-0">{{ article.title }}</td>
        <td class="w-12.5p">{{ article.code }}</td>
        <td class="w-12.5p">{{ article.finishes.join(' ') }}</td>
        <td class="w-12.5p">{{ formatArticleDimensions(article.dimensions) }}</td>
        <td class="w-12.5p pe-1 text-end">{{ article.quantity }}</td>
      </tr>
    </tbody>
  </table>