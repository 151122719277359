import { Injectable } from '@angular/core';
import { OrderArticlesListRow, OrderArticlesListRowInterface, pageBreakKey, RowTypes } from '../../order-articles-list.interface';
import { ExtraListElementRowInterface } from '../../order-articles-list/components/extra-row/extra-items.model';
import { Observable, Subject } from 'rxjs';
import {deepFlattenGroups, flattenDeep} from "../../../../core/util/flatten-deep/flatten-deep.helper";
import { GROUP_LIKE_ROW_TYPES } from '../../../../core/services/order-items-group/order-items-group.service';

@Injectable({
  providedIn: 'root',
})
export class SelectedRowsService {
  private selectedRows: OrderArticlesListRow[] = [];
  private selectedRows$: Subject<OrderArticlesListRow[]> = new Subject<
  OrderArticlesListRow[]
  >();

  constructor() {}

  onSelect(row: OrderArticlesListRow, selected: boolean, single = true) {
    if (selected) {
      if (!this.selectedRows.find(searching => searching.id === row.id)) {
        this.selectedRows.push(row);
      }
    } else {
      const rowIndex = this.selectedRows.findIndex(filterableRow => filterableRow.id === row.id && filterableRow.rowType === row.rowType);
      if (rowIndex > -1) {
        const removed = this.selectedRows.splice(rowIndex, 1)[0];
        this.unselectGroup(removed);
      }
    }
    this.selectedRows = this.sortRows(this.selectedRows);
    if (single) {
      this.selectedRows$.next(this.selectedRows);
    }
  }

  getSelectedItemsAndParent(
    selectedRows: OrderArticlesListRow[]
  ): {
    firstSelectedItem: OrderArticlesListRow;
    lastSelectedItem: OrderArticlesListRow;
    parent: ExtraListElementRowInterface;
  } {
    let firstSelectedItem, lastSelectedItem, parent;
    if (selectedRows.length > 0) {
      const lowestGroupLevel = Math.min.apply(
        Math,
        selectedRows.map(({ groupLevel }) => groupLevel)
      );
      const lowestGroupLevelRows = selectedRows.filter(row => row.groupLevel === lowestGroupLevel);
      [firstSelectedItem] = lowestGroupLevelRows;
      if (firstSelectedItem.rowType === RowTypes.GROUP && lowestGroupLevelRows.length === 1) {
        parent = firstSelectedItem;
      }
      lastSelectedItem = selectedRows[selectedRows.length - 1];
    }

    return { firstSelectedItem, lastSelectedItem, parent };
  }

  private unselectGroup(childRow: OrderArticlesListRow) {
    const group = childRow[pageBreakKey(childRow)];
    if (group && group.id && this.isSelected(group) && !this.groupHasSelectedChildren(group)) {
      this.selectedRows.splice(
        this.selectedRows.findIndex(selectedRow => selectedRow.id === group.id),
        1
      );
    }
  }

  onSelectAllRows(rows: OrderArticlesListRow[], selected: boolean) {
    rows.forEach(row => {
      this.selectRow(row, selected);
    });

    this.selectedRows$.next(this.selectedRows);
  }

  selectRow(row: OrderArticlesListRow, selected: boolean) {
    if (GROUP_LIKE_ROW_TYPES.includes(row.rowType)) {
      this.onGroupSelect(row, selected);
      return;
    }
    this.onSelect(row, selected, false);

    this.selectedRows$.next(this.selectedRows);
  }

  onGroupSelect(row: OrderArticlesListRow, selected: boolean) {
    const flattenRows = deepFlattenGroups([row]);

    flattenRows.forEach(flattenedRow => {
      this.onSelect(flattenedRow, selected, false);
    });

    this.selectedRows$.next(this.selectedRows);
  }

  isSelected(row: OrderArticlesListRow): boolean {
    return !!this.selectedRows.find(selectedRow => selectedRow.id === row.id);
  }

  isSelectedByRowAndType(row: OrderArticlesListRow, rowType: RowTypes) {
    return !!this.selectedRows.find(selectedRow => selectedRow.id === row.id && selectedRow.rowType === rowType);
  }

  selectedRowsCount(): number {
    return this.selectedRows.length;
  }

  resetSelectedRows() {
    this.selectedRows = [];
    this.selectedRows$.next(this.selectedRows);
  }

  getSelectedRowsAsObservable(): Observable<OrderArticlesListRow[]> {
    return this.selectedRows$.asObservable();
  }

  updateSelectedRows(rows: OrderArticlesListRow[]) {
    const flattenedRows = flattenDeep(rows);

    const findRow = selectedRow => flattenedRows.find(row => selectedRow.rowType === row.rowType && selectedRow.id === row.id);

    this.selectedRows = this.selectedRows
      .filter(selectedRow => {
        return !!findRow(selectedRow);
      })
      .map(selectedRow => {
        return findRow(selectedRow);
      });
    this.selectedRows$.next(this.selectedRows);
  }

  getSelectedRows() {
    return this.selectedRows;
  }

  private sortRows(rows: OrderArticlesListRow[]): OrderArticlesListRow[] {
    return rows.sort((a, b) => a.position - b.position);
  }

  private groupHasSelectedChildren(group: OrderArticlesListRow): boolean {
    return this.selectedRows.some(row => row[pageBreakKey(row)]?.id === group.id);
  }

  initialCall() {
    this.updateSelectedRows([]);
  }
}
